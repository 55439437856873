// src/components/Contact.jsx
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Mail, User, MessageCircle, Building } from "lucide-react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
    interest: "general",
  });

  const [consents, setConsents] = useState({
    dataProcessing: false,
    marketing: false,
    privacyPolicy: false,
  });

  const [status, setStatus] = useState({
    success: false,
    error: "",
    isSubmitting: false,
  });

  const recaptchaRef = useRef();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      setStatus({ ...status, error: "Name is required" });
      return false;
    }
    if (!formData.email.trim() || !validateEmail(formData.email)) {
      setStatus({ ...status, error: "Valid email is required" });
      return false;
    }
    if (!formData.message.trim()) {
      setStatus({ ...status, error: "Message is required" });
      return false;
    }
    if (!consents.dataProcessing || !consents.privacyPolicy) {
      setStatus({
        ...status,
        error: "Please accept the required consent terms",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const token = recaptchaRef.current.getValue();
    if (!token) {
      setStatus({ ...status, error: "Please verify you are not a robot" });
      return;
    }

    setStatus({ ...status, isSubmitting: true });

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          ...formData,
          marketingConsent: consents.marketing,
          "g-recaptcha-response": token,
        },
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      );

      setStatus({
        success: true,
        error: "",
        isSubmitting: false,
      });

      // Reset form
      setFormData({
        name: "",
        email: "",
        company: "",
        message: "",
        interest: "general",
      });
      setConsents({
        dataProcessing: false,
        marketing: false,
        privacyPolicy: false,
      });
      recaptchaRef.current.reset();
    } catch (error) {
      setStatus({
        success: false,
        error: "Failed to send message. Please try again.",
        isSubmitting: false,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleConsentChange = (e) => {
    const { name, checked } = e.target;
    setConsents((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <section id="contact" className="py-20 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid lg:grid-cols-12 gap-[30px] items-center">
          {/* Contact Info */}
          <div className="lg:col-span-5">
            <h4 className="mb-4 text-2xl leading-normal font-medium">
              Ready to take the next step?
            </h4>
            <p className="text-gray-600 mb-6">
              Contact us today to discuss your AWS cloud needs and discover how
              our team of certified experts can help you achieve your business
              goals.
            </p>
            <div className="bg-white rounded-lg p-6 shadow-md">
              <h5 className="font-medium mb-4">Important Information:</h5>
              <ul className="text-sm text-gray-600 space-y-2">
                <li>
                  • Your data is processed securely and in compliance with GDPR
                </li>
                <li>
                  • We never share your information with third parties without
                  consent
                </li>
                <li>• You can request deletion of your data at any time</li>
                <li>
                  • For CCPA-specific rights, please see our Privacy Policy
                </li>
              </ul>
            </div>
          </div>

          {/* Contact Form */}
          <div className="lg:col-span-7">
            <div className="bg-white rounded-lg shadow-lg p-8">
              <h3 className="text-2xl font-medium mb-6">Get in touch</h3>

              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Form Fields */}
                <div className="space-y-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Name <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <User
                        className="absolute left-3 top-3 text-gray-400"
                        size={20}
                      />
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <Mail
                        className="absolute left-3 top-3 text-gray-400"
                        size={20}
                      />
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="company"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Company
                    </label>
                    <div className="relative">
                      <Building
                        className="absolute left-3 top-3 text-gray-400"
                        size={20}
                      />
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="message"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Message <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <MessageCircle
                        className="absolute left-3 top-3 text-gray-400"
                        size={20}
                      />
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        rows="4"
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>

                {/* Consent Checkboxes */}
                <div className="space-y-4 border-t pt-4">
                  <div className="flex items-start">
                    <input
                      type="checkbox"
                      id="dataProcessing"
                      name="dataProcessing"
                      checked={consents.dataProcessing}
                      onChange={handleConsentChange}
                      className="mt-1 h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                      required
                    />
                    <label
                      htmlFor="dataProcessing"
                      className="ml-2 block text-sm text-gray-600"
                    >
                      I consent to the processing of my personal data as
                      described in the{" "}
                      <Link
                        to="/privacy-policy"
                        className="text-teal-600 hover:text-teal-700 underline"
                      >
                        Privacy Policy
                      </Link>
                      . <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div className="flex items-start">
                    <input
                      type="checkbox"
                      id="privacyPolicy"
                      name="privacyPolicy"
                      checked={consents.privacyPolicy}
                      onChange={handleConsentChange}
                      className="mt-1 h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                      required
                    />
                    <label
                      htmlFor="privacyPolicy"
                      className="ml-2 block text-sm text-gray-600"
                    >
                      I have read and accept the{" "}
                      <Link
                        to="/terms-of-service"
                        className="text-teal-600 hover:text-teal-700 underline"
                      >
                        Terms of Service
                      </Link>
                      . <span className="text-red-500">*</span>
                    </label>
                  </div>
                  {/* 
                  <div className="flex items-start">
                    <input
                      type="checkbox"
                      id="marketing"
                      name="marketing"
                      checked={consents.marketing}
                      onChange={handleConsentChange}
                      className="mt-1 h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="marketing"
                      className="ml-2 block text-sm text-gray-600"
                    >
                      I would like to receive updates about products, services,
                      and events. You can unsubscribe at any time.
                    </label>
                  </div>
                  */}
                </div>

                {/* ReCAPTCHA */}
                <div className="mt-4">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={status.isSubmitting}
                  className={`w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-white font-medium 
                    ${
                      status.isSubmitting
                        ? "bg-teal-400 cursor-not-allowed"
                        : "bg-teal-600 hover:bg-teal-700"
                    }`}
                >
                  {status.isSubmitting ? "Sending..." : "Send Message"}
                </button>

                {/* Status Messages */}
                {status.success && (
                  <div className="mt-4 p-4 bg-green-50 border border-green-500 text-green-700 rounded-md">
                    Thank you for your message! We'll get back to you soon.
                  </div>
                )}

                {status.error && (
                  <div className="mt-4 p-4 bg-red-50 border border-red-500 text-red-700 rounded-md">
                    {status.error}
                  </div>
                )}

                {/* Data Processing Notice */}
                <p className="mt-4 text-xs text-gray-500">
                  By submitting this form, your information will be processed in
                  accordance with our{" "}
                  <Link
                    to="/privacy-policy"
                    className="text-teal-600 hover:text-teal-700 underline"
                  >
                    Privacy Policy
                  </Link>
                  . For California residents, please see our{" "}
                  <Link
                    to="/ccpa-notice"
                    className="text-teal-600 hover:text-teal-700 underline"
                  >
                    CCPA Notice
                  </Link>
                  .
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
