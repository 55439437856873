// src/pages/TermsOfService.jsx
import React from "react";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div className="py-20 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <p className="text-gray-600 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-semibold mb-4">
                1. Agreement to Terms
              </h2>
              <p className="text-gray-700">
                These Terms of Service ("Terms") constitute a legally binding
                agreement between you and Spoecker Cloud Consulting LLC
                ("Company," "we," "us," or "our") regarding your access to and
                use of our website at spoecker.cloud and our services.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">2. Services</h2>
              <p className="text-gray-700 mb-4">
                We provide cloud consulting services, including but not limited
                to:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>AWS architecture and implementation</li>
                <li>Cloud migration and optimization</li>
                <li>DevOps and CI/CD implementation</li>
                <li>GenAI solutions</li>
                <li>Training and certification preparation</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                3. Intellectual Property Rights
              </h2>
              <p className="text-gray-700">
                The Service and its original content, features, and
                functionality are and will remain the exclusive property of
                Spoecker Cloud Consulting LLC and its licensors. The Service is
                protected by copyright, trademark, and other laws of both the
                United States and foreign countries.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                4. User Responsibilities
              </h2>
              <p className="text-gray-700 mb-4">
                By using our Service, you agree to:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>Provide accurate and complete information</li>
                <li>Maintain the confidentiality of your account</li>
                <li>Notify us immediately of any security breaches</li>
                <li>Use the services in compliance with all applicable laws</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                5. Service Engagement
              </h2>
              <p className="text-gray-700 mb-4">
                Specific terms of engagement, including scope, deliverables, and
                pricing, will be detailed in separate service agreements or
                statements of work. These Terms govern your use of our website
                and general service engagement principles.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                6. Limitation of Liability
              </h2>
              <p className="text-gray-700">
                To the maximum extent permitted by law, in no event shall
                Spoecker Cloud Consulting LLC be liable for any indirect,
                punitive, incidental, special, consequential, or exemplary
                damages, including without limitation damages for loss of
                profits, goodwill, use, data, or other intangible losses,
                arising out of or relating to the use of, or inability to use,
                the service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">7. Privacy</h2>
              <p className="text-gray-700">
                Your privacy is important to us. Please review our{" "}
                <Link
                  to="/privacy-policy"
                  className="text-teal-600 hover:text-teal-700 underline"
                >
                  Privacy Policy
                </Link>
                , which explains how we collect, use, and share information
                about you when you use our services.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                8. Changes to Terms
              </h2>
              <p className="text-gray-700">
                We reserve the right to modify these terms at any time. We will
                notify you of any changes by posting the new Terms on this page
                and updating the "Last updated" date.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">9. Governing Law</h2>
              <p className="text-gray-700">
                These Terms shall be governed by and construed in accordance
                with the laws of the State of [Your State], without regard to
                its conflict of law provisions.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                10. Contact Information
              </h2>
              <p className="text-gray-700">
                If you have any questions about these Terms, please contact us
                at:
              </p>
              <div className="mt-4 text-gray-700">
                <p>Spoecker Cloud Consulting LLC</p>
                <p>Email: legal@spoecker.cloud</p>
                <p>Spoecker Cloud Consulting LLC</p>
                <p>3833 POWERLINE RD SUITE 201</p>
                <p>FT LAUDERDALE, FL 33309</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
