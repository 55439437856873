// src/components/Solutions.jsx
import React from "react";
import { Container, Code2, Brain, BarChart, Check } from "lucide-react";

const Solutions = () => {
  const solutions = [
    {
      Icon: Container,
      title: "Container Solutions",
      description: "Advanced ECS/EKS architectures and microservices",
      features: [
        "Amazon ECS & EKS Architecture Design",
        "Container Security & Compliance",
        "CI/CD Pipeline Implementation",
        "Blue/Green Deployments",
        "Service Mesh with AWS App Mesh",
        "Cost Optimization for Containers",
      ],
      highlight:
        "Expert in container orchestration and microservices architecture",
    },
    {
      Icon: Code2,
      title: "Serverless",
      description: "Event-driven architectures and Lambda solutions",
      features: [
        "AWS Lambda Architecture Design",
        "API Gateway Implementation",
        "Event-Driven Solutions",
        "Serverless Framework",
        "Step Functions Workflows",
        "Performance Optimization",
      ],
      highlight:
        "Reduce costs and improve scalability with serverless architectures",
    },
    {
      Icon: Brain,
      title: "GenAI & ML",
      description: "Secure and scaleable private data AI solutions",
      features: [
        "Private Data Processing",
        "Custom GenAI Solutions",
        "3rd Party Model Integration",
        "Amazon Bedrock Integration",
        "SageMaker Implementation",
        "MLOps Workflows",
        "AI Security & Compliance",
      ],
      highlight: "Secure and compliant AI solutions for your business",
    },
    {
      Icon: BarChart,
      title: "Data Analytics",
      description: "Advanced analytics and visualization solutions",
      features: [
        "Data Lake Architecture",
        "ETL Pipeline Design",
        "Real-time Analytics",
        "QuickSight Dashboards",
        "Athena & Glue Integration",
        "Data Security & Governance",
      ],
      highlight: "Transform your data into actionable insights",
    },
  ];

  return (
    <section id="solutions" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Core Solutions</h2>
          <p className="text-gray-600 max-w-3xl mx-auto">
            Comprehensive AWS solutions tailored to your business needs. From
            container orchestration to secure AI implementations, we deliver
            enterprise-grade solutions with proven expertise.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {solutions.map((solution, index) => {
            const IconComponent = solution.Icon;
            return (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition-shadow duration-300"
              >
                <div className="p-6">
                  <div className="flex justify-center mb-6">
                    <IconComponent
                      size={48}
                      className="text-teal-500 group-hover:scale-110 transition-transform duration-300"
                    />
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-center">
                    {solution.title}
                  </h3>
                  <p className="text-gray-600 text-center mb-6">
                    {solution.description}
                  </p>
                </div>

                <div className="bg-gray-50 p-6 border-t border-gray-100">
                  <p className="text-sm text-teal-600 font-medium mb-4">
                    {solution.highlight}
                  </p>
                  <ul className="space-y-3">
                    {solution.features.map((feature, idx) => (
                      <li key={idx} className="flex items-start">
                        <Check className="w-5 h-5 text-teal-500 flex-shrink-0 mr-2 mt-0.5" />
                        <span className="text-sm text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="p-6 bg-white border-t border-gray-100">
                  <button
                    onClick={() =>
                      document
                        .getElementById("contact")
                        .scrollIntoView({ behavior: "smooth" })
                    }
                    className="w-full py-2 px-4 bg-teal-500 hover:bg-teal-600 text-white rounded-lg transition-colors duration-300"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Solutions;
