// src/components/Speaking.jsx
import React from "react";
import { Presentation } from "lucide-react";

const Speaking = () => {
  const speakingEngagements = [
    {
      event: "AWS Summit Thailand 2024",
      type: "Community Speaker",
      topic: "ECS Blue/Green Deployments with AWS CodeDeploy",
    },
    {
      event: "AWS Community Day Thailand 2025",
      type: "Community Speaker",
      topic:
        "Starting from a containerised POC and applying best practices/Well Architected Framework",
    },
    {
      event: "AWS Cloud Day Thailand 2023",
      type: "Community Speaker",
      topic:
        "Accelerate Machine Learning Project with Amazon SageMaker Jumpstart",
    },
  ];

  return (
    <section id="speaking" className="py-20 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <Presentation size={48} className="text-teal-500 mx-auto mb-4" />
          <h2 className="text-3xl font-bold">Speaking Engagements</h2>
          <p className="text-gray-600 mt-4">
            AWS Community Builder & Conference Speaker
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {speakingEngagements.map((engagement, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md transform hover:scale-105 transition duration-300"
            >
              <h3 className="text-xl font-semibold mb-2">{engagement.event}</h3>
              <div className="text-teal-500 font-medium mb-3">
                {engagement.type}
              </div>
              <p className="text-gray-600">{engagement.topic}</p>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <div className="inline-block bg-teal-50 border border-teal-200 rounded-lg px-6 py-4">
            <p className="text-teal-800">
              Interested in having me speak at your event?
              <a
                href="#contact"
                className="text-teal-600 font-semibold hover:text-teal-700 ml-2"
              >
                Get in touch
              </a>
            </p>
          </div>
        </div>

        {/* AWS Community Builder Badge */}
        <div className="mt-16 bg-white rounded-lg shadow-md p-8">
          <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-8">
            <div className="bg-gray-50 p-4 rounded-lg">
              <img
                src="assets/images/aws/Community Builders badge 800px.png"
                alt="AWS Community Builder Badge"
                className="w-24 h-24"
              />
            </div>
            <div className="text-center md:text-left">
              <h3 className="text-xl font-semibold mb-2">
                AWS Community Builder
              </h3>
              <p className="text-gray-600 max-w-xl">
                Recognized AWS Community Builder contributing to the AWS
                community through speaking engagements, technical content, and
                knowledge sharing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Speaking;
