// src/components/SEOMetaTags.jsx
import React from "react";
import { Helmet } from "react-helmet-async";

const SEOMetaTags = ({
  title = "Spoecker Cloud Consulting - AWS Solutions Expert",
  description = "Expert AWS consulting services including cloud architecture, DevOps, and secure GenAI solutions. AWS Authorized Instructor and certified expert.",
  canonicalUrl = "https://spoecker.cloud",
  ogImage = "/assets/images/og-image.jpg",
}) => {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />

      {/* Additional SEO Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Spoecker Cloud Consulting LLC" />

      {/* Accessibility Tags */}
      <html lang="en" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "Spoecker Cloud Consulting LLC",
            "description": "${description}",
            "image": "${ogImage}",
            "url": "${canonicalUrl}",
            "sameAs": [
              "https://www.linkedin.com/company/spoecker-cloud-consulting"
            ],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "[Your Street Address]",
              "addressLocality": "[Your City]",
              "addressRegion": "[Your State]",
              "postalCode": "[Your ZIP]",
              "addressCountry": "[Your Country]"
            },
            "serviceType": [
              "AWS Consulting",
              "Cloud Architecture",
              "DevOps Services",
              "GenAI Solutions"
            ],
            "areaServed": {
              "@type": "GeoCircle",
              "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": "[Your Latitude]",
                "longitude": "[Your Longitude]"
              }
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEOMetaTags;
