// src/components/GenAI.jsx
import React from "react";
import { Brain, Lock, Server, Code2, Shield, Globe } from "lucide-react";

const GenAI = () => {
  return (
    <section id="genai" className="py-20 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <Brain size={48} className="text-teal-500 mx-auto mb-4" />
          <h2 className="text-3xl font-bold mb-4">Enterprise GenAI Platform</h2>
          <p className="text-gray-600 max-w-3xl mx-auto">
            Secure, compliant, and controlled GenAI solutions for businesses
            that take data privacy seriously
          </p>
        </div>

        {/* Data Security & Compliance Section */}
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          <div className="bg-teal-50 border-l-4 border-teal-500 p-6 rounded-r-lg">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <Shield className="h-6 w-6 text-teal-500" />
              </div>
              <div className="ml-3">
                <h3 className="text-lg font-medium text-teal-800">
                  Complete Data Control & Transparency
                </h3>
                <p className="mt-2 text-teal-700">
                  Your data sovereignty is our priority. Unlike public GenAI
                  solutions, our platform gives you full control over where your
                  data is processed and stored, with guaranteed AWS regional
                  isolation.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-teal-50 border-l-4 border-teal-500 p-6 rounded-r-lg">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <Globe className="h-6 w-6 text-teal-500" />
              </div>
              <div className="ml-3">
                <h3 className="text-lg font-medium text-teal-800">
                  Regional Compliance Guaranteed
                </h3>
                <p className="mt-2 text-teal-700">
                  Meet regulatory requirements with ease. Choose your AWS
                  regions in Europe, US, or any other supported location to
                  ensure compliance with GDPR, HIPAA, and other data protection
                  regulations.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center">
              <Lock size={32} className="text-teal-500 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                Enterprise-Grade Security
              </h3>
              <p className="text-center text-gray-600">
                Your data is always encrypted and processed with comprehensive
                security controls
              </p>
            </div>
            <div className="flex flex-col items-center">
              <Server size={32} className="text-teal-500 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                Fully Managed Service
              </h3>
              <p className="text-center text-gray-600">
                Zero infrastructure management with automatic scaling and high
                availability
              </p>
            </div>
            <div className="flex flex-col items-center">
              <Code2 size={32} className="text-teal-500 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                Seamless Integration
              </h3>
              <p className="text-center text-gray-600">
                Simple API integration with your existing applications and
                security controls
              </p>
            </div>
          </div>

          <div className="mt-12 text-center">
            <p className="text-lg text-gray-700 max-w-3xl mx-auto mb-8">
              Deploy enterprise-ready GenAI solutions that respect your data
              privacy requirements. Perfect for financial services, healthcare,
              and other regulated industries requiring strict data control.
            </p>

            <div className="flex flex-col items-center space-y-4">
              <button
                onClick={() =>
                  document
                    .getElementById("contact")
                    .scrollIntoView({ behavior: "smooth" })
                }
                className="bg-teal-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-teal-700 transition duration-300 flex items-center space-x-2"
              >
                <Brain size={20} />
                <span>Schedule Your Private GenAI Demo</span>
              </button>
              <p className="text-gray-600">
                See how our secure GenAI platform can transform your business
                while maintaining complete data sovereignty
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GenAI;
