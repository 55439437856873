// src/pages/PrivacyPolicy.jsx
import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="py-20 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <p className="text-gray-600 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
              <p className="text-gray-700">
                Spoecker Cloud Consulting LLC ("we," "our," or "us") respects
                your privacy and is committed to protecting your personal data.
                This privacy policy describes how we collect, use, and share
                information when you use our website and services.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                2. Information We Collect
              </h2>
              <h3 className="text-xl font-medium mb-2">
                2.1 Information You Provide
              </h3>
              <p className="text-gray-700 mb-4">
                We collect information that you provide directly to us,
                including:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>
                  Name and contact information when you fill out our contact
                  form
                </li>
                <li>Email address for communications</li>
                <li>Company information if provided</li>
                <li>Message content from our contact form</li>
              </ul>

              <h3 className="text-xl font-medium mt-6 mb-2">
                2.2 Information Collected Automatically
              </h3>
              <p className="text-gray-700 mb-4">
                When you visit our website, we automatically collect:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>IP address and device information</li>
                <li>Browser type and settings</li>
                <li>Website usage data</li>
                <li>Cookies and similar tracking technologies</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                3. How We Use Your Information
              </h2>
              <p className="text-gray-700 mb-4">
                We use the collected information for:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>Providing and improving our services</li>
                <li>Communicating with you about our services</li>
                <li>Processing your requests and inquiries</li>
                <li>Analyzing and improving our website performance</li>
                <li>Complying with legal obligations</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">4. Your Rights</h2>
              <p className="text-gray-700 mb-4">
                Under applicable data protection laws, you have the right to:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>Access your personal data</li>
                <li>Correct inaccurate data</li>
                <li>Request deletion of your data</li>
                <li>Object to processing of your data</li>
                <li>Request transfer of your data</li>
                <li>Withdraw consent</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">5. Cookies</h2>
              <p className="text-gray-700 mb-4">
                We use cookies and similar tracking technologies to track
                activity on our website and hold certain information. You can
                instruct your browser to refuse all cookies or to indicate when
                a cookie is being sent. See our{" "}
                <Link
                  to="/cookie-policy"
                  className="text-teal-600 hover:text-teal-700 underline"
                >
                  Cookie Policy
                </Link>{" "}
                for more information.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">6. Contact Us</h2>
              <p className="text-gray-700">
                For any questions about this Privacy Policy, please contact us
                at:
              </p>
              <div className="mt-4 text-gray-700">
                <p>Spoecker Cloud Consulting LLC</p>
                <p>Email: privacy@spoecker.cloud</p>
                <p>Spoecker Cloud Consulting LLC</p>
                <p>3833 POWERLINE RD SUITE 201</p>
                <p>FT LAUDERDALE, FL 33309</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
