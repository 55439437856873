// src/components/Footer.jsx
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const location = useLocation();

  const handleInternalLink = (href) => {
    if (href.startsWith("#")) {
      if (location.pathname !== "/") {
        // If we're not on home page, navigate and then scroll
        navigate("/", { state: { scrollTo: href.substring(1) } });
      } else {
        // If we're on home page, just scroll
        const element = document.getElementById(href.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  const sections = [
    {
      title: "Solutions",
      links: [
        { label: "Private Data GenAI", href: "#genai" },
        { label: "Container Solutions", href: "#solutions" },
        { label: "Serverless Architecture", href: "#solutions" },
        { label: "Data Analytics", href: "#solutions" },
      ],
    },
    {
      title: "Services",
      links: [
        { label: "AWS Consulting", href: "#solutions" },
        { label: "DevOps Transformation", href: "#devops" },
        { label: "Cloud Training", href: "#contact" },
        { label: "Speaking Engagements", href: "#speaking" },
      ],
    },
    {
      title: "Company",
      links: [
        { label: "About", href: "#about" },
        { label: "Contact", href: "#contact" },
        { label: "AWS Certifications", href: "#expertise" },
      ],
    },
  ];

  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="max-w-7xl mx-auto px-4">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          {/* Company Info */}
          <div>
            <Link to="/">
              <img
                src="/assets/images/logo_white.png"
                alt="Spoecker Cloud Consulting Logo"
                className="h-8 mb-4"
              />
            </Link>
            <p className="text-gray-400">
              AWS Solutions Expert & Authorized Instructor
            </p>
            <div className="mt-4">
              <p className="text-gray-400">AWS Community Builder</p>
            </div>
          </div>

          {/* Navigation Sections */}
          {sections.map((section, index) => (
            <div key={index}>
              <h3 className="text-lg font-semibold mb-4">{section.title}</h3>
              <ul className="space-y-2">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <button
                      onClick={() => handleInternalLink(link.href)}
                      className="text-gray-400 hover:text-white transition duration-300 text-left"
                    >
                      {link.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Legal Links Section */}
        <div className="border-t border-gray-700 pt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="text-gray-400 text-sm">
              <p>
                &copy; {currentYear} Spoecker Cloud Consulting LLC. All rights
                reserved.
              </p>
            </div>
            <div className="flex flex-wrap gap-4 md:justify-end text-sm">
              <Link
                to="/privacy-policy"
                className="text-gray-400 hover:text-white"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-of-service"
                className="text-gray-400 hover:text-white"
              >
                Terms of Service
              </Link>
              <Link
                to="/cookie-policy"
                className="text-gray-400 hover:text-white"
              >
                Cookie Policy
              </Link>
              <Link
                to="/accessibility"
                className="text-gray-400 hover:text-white"
              >
                Accessibility
              </Link>
              <a
                href="mailto:info@spoecker.cloud"
                className="text-gray-400 hover:text-white"
              >
                info@spoecker.cloud
              </a>
            </div>
          </div>
        </div>

        {/* Optional: Additional Legal Information */}
        <div className="mt-8 text-xs text-gray-500 text-center">
          <p>
            This website uses cookies to ensure you get the best experience.{" "}
            <Link to="/cookie-policy" className="underline hover:text-gray-400">
              Learn more
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
