// src/pages/AccessibilityStatement.jsx
import React from "react";

const AccessibilityStatement = () => {
  return (
    <div className="py-20 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8">Accessibility Statement</h1>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <p className="text-gray-600 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-semibold mb-4">Our Commitment</h2>
              <p className="text-gray-700">
                Spoecker Cloud Consulting LLC is committed to ensuring digital
                accessibility for people with disabilities. We are continually
                improving the user experience for everyone and applying the
                relevant accessibility standards.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Measures to Support Accessibility
              </h2>
              <p className="text-gray-700 mb-4">
                We take the following measures to ensure accessibility of our
                website:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>Include accessibility throughout our internal policies</li>
                <li>Provide continual accessibility training for our staff</li>
                <li>Assign clear accessibility goals and responsibilities</li>
                <li>Employ formal accessibility quality assurance methods</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Conformance Status
              </h2>
              <p className="text-gray-700 mb-4">
                The Web Content Accessibility Guidelines (WCAG) defines
                requirements for designers and developers to improve
                accessibility for people with disabilities. It defines three
                levels of conformance: Level A, Level AA, and Level AAA. Our
                website is partially conformant with WCAG 2.1 level AA.
                Partially conformant means that some parts of the content do not
                fully conform to the accessibility standard.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Technical Specifications
              </h2>
              <p className="text-gray-700 mb-4">
                Accessibility of our website relies on the following
                technologies:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>HTML5</li>
                <li>WAI-ARIA</li>
                <li>CSS3</li>
                <li>JavaScript</li>
              </ul>
              <p className="text-gray-700 mt-4">
                These technologies are relied upon for conformance with the
                accessibility standards used.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Assessment Approach
              </h2>
              <p className="text-gray-700 mb-4">
                Spoecker Cloud Consulting LLC assessed the accessibility of our
                website by the following approaches:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>Self-evaluation</li>
                <li>External evaluation</li>
                <li>Automated evaluation tools</li>
                <li>User feedback</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">Feedback</h2>
              <p className="text-gray-700 mb-4">
                We welcome your feedback on the accessibility of our website.
                Please let us know if you encounter accessibility barriers:
              </p>
              <div className="text-gray-700">
                <p>E-mail: accessibility@spoecker.cloud</p>
                <p>Postal Address:</p>
                <p>Spoecker Cloud Consulting LLC</p>
                <p>3833 POWERLINE RD SUITE 201</p>
                <p>FT LAUDERDALE, FL 33309</p>
              </div>
              <p className="text-gray-700 mt-4">
                We try to respond to feedback within 3 business days.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessibilityStatement;
