// src/components/SchemaMarkup.jsx
import React from "react";
import { Helmet } from "react-helmet-async";

const SchemaMarkup = () => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://spoecker.cloud/#organization",
        name: "Spoecker Cloud Consulting LLC",
        url: "https://spoecker.cloud",
        logo: {
          "@type": "ImageObject",
          url: "https://spoecker.cloud/assets/images/logo.png",
        },
        sameAs: ["https://www.linkedin.com/company/spoecker-cloud-consulting"],
      },
      {
        "@type": "ProfessionalService",
        "@id": "https://spoecker.cloud/#service",
        name: "Spoecker Cloud Consulting LLC",
        provider: {
          "@id": "https://spoecker.cloud/#organization",
        },
        serviceType: [
          "AWS Consulting",
          "Cloud Architecture",
          "DevOps Implementation",
          "GenAI Solutions",
        ],
        areaServed: "Worldwide",
        hasOfferCatalog: {
          "@type": "OfferCatalog",
          name: "Cloud Services",
          itemListElement: [
            {
              "@type": "Offer",
              itemOffered: {
                "@type": "Service",
                name: "AWS Consulting",
                description:
                  "Expert AWS architecture and implementation services",
              },
            },
            {
              "@type": "Offer",
              itemOffered: {
                "@type": "Service",
                name: "GenAI Solutions",
                description: "Secure private data GenAI implementations",
              },
            },
          ],
        },
      },
      {
        "@type": "Person",
        "@id": "https://spoecker.cloud/#founder",
        name: "Alexander Spoecker",
        jobTitle: "AWS Authorized Instructor",
        worksFor: {
          "@id": "https://spoecker.cloud/#organization",
        },
        knowsAbout: [
          "AWS Cloud Architecture",
          "DevOps",
          "Container Solutions",
          "Serverless Computing",
        ],
      },
    ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default SchemaMarkup;
