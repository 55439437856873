// src/pages/CCPANotice.jsx
import React from "react";
import { Link } from "react-router-dom";

const CCPANotice = () => {
  return (
    <div className="py-20 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8">
          California Consumer Privacy Act (CCPA) Notice
        </h1>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <p className="text-gray-600 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Your California Privacy Rights
              </h2>
              <p className="text-gray-700">
                If you are a California resident, you have specific rights
                regarding your personal information under the California
                Consumer Privacy Act (CCPA). This notice supplements our Privacy
                Policy and explains your rights and how to exercise them.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Information We Collect
              </h2>
              <p className="text-gray-700 mb-4">
                We collect personal information as described in our Privacy
                Policy. Under the CCPA, this includes:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>Identifiers (name, email address, IP address)</li>
                <li>Professional information (company name, job title)</li>
                <li>Internet activity (website usage, cookies)</li>
                <li>Communications preferences</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Your Rights Under CCPA
              </h2>
              <div className="space-y-4">
                <div>
                  <h3 className="text-xl font-medium mb-2">Right to Know</h3>
                  <p className="text-gray-700">
                    You have the right to request information about the personal
                    information we've collected about you in the past 12 months.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-2">Right to Delete</h3>
                  <p className="text-gray-700">
                    You can request deletion of your personal information,
                    subject to certain exceptions.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-2">Right to Opt-Out</h3>
                  <p className="text-gray-700">
                    You have the right to opt-out of the sale of your personal
                    information. However, we do not sell personal information.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-2">
                    Right to Non-Discrimination
                  </h3>
                  <p className="text-gray-700">
                    We will not discriminate against you for exercising your
                    CCPA rights.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                How to Exercise Your Rights
              </h2>
              <p className="text-gray-700 mb-4">
                To exercise your rights under CCPA, you can:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>Email us at privacy@spoecker.cloud</li>
                <li>Submit a request through our contact form</li>
                <li>Call us at [Your Phone Number]</li>
              </ul>
              <p className="text-gray-700 mt-4">
                We will respond to your request within 45 days.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Verification Process
              </h2>
              <p className="text-gray-700">
                To protect your information, we need to verify your identity
                before responding to your request. We will match the identifying
                information you provide to the personal information we maintain
                about you.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">Authorized Agent</h2>
              <p className="text-gray-700">
                You can designate an authorized agent to make a request under
                the CCPA on your behalf. We will require the authorized agent to
                provide proof of your written permission and verify their own
                identity with us.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Contact Information
              </h2>
              <p className="text-gray-700">
                If you have any questions about this CCPA Notice or our privacy
                practices, please contact us at:
              </p>
              <div className="mt-4 text-gray-700">
                <p>Spoecker Cloud Consulting LLC</p>
                <p>Email: privacy@spoecker.cloud</p>
                <p>[Your Business Address]</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCPANotice;
