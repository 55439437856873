// src/App.jsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import CookiePolicy from "./pages/CookiePolicy";
import AccessibilityStatement from "./pages/AccessibilityStatement";
import CCPANotice from "./pages/CCPANotice";
import DataProcessingAgreement from "./pages/DataProcessingAgreement";
import CookieConsent from "./components/CookieConsent";
import SEOMetaTags from "./components/SEOMetaTags";
import SchemaMarkup from "./components/SchemaMarkup";
import SkipToMain from "./components/SkipToMain";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <SEOMetaTags />
        <SchemaMarkup />
        <SkipToMain />
        <CookieConsent />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
            <Route path="accessibility" element={<AccessibilityStatement />} />
            <Route path="ccpa-notice" element={<CCPANotice />} />
            <Route
              path="data-processing-agreement"
              element={<DataProcessingAgreement />}
            />
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
