// src/components/SkipToMain.jsx
import React from "react";
import { skipToMainContent } from "../utils/accessibility";

const SkipToMain = () => {
  return (
    <button
      onClick={skipToMainContent}
      className="sr-only focus:not-sr-only focus:absolute focus:top-0 focus:left-0 
                 focus:z-50 focus:p-4 focus:bg-white focus:text-teal-600 
                 focus:shadow-lg focus:outline-none"
      aria-label="Skip to main content"
    >
      Skip to main content
    </button>
  );
};

export default SkipToMain;
