// src/pages/CookiePolicy.jsx
import React from "react";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  return (
    <div className="py-20 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8">Cookie Policy</h1>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <p className="text-gray-600 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-semibold mb-4">
                1. What Are Cookies
              </h2>
              <p className="text-gray-700">
                Cookies are small text files that are placed on your computer or
                mobile device when you visit our website. They are widely used
                to make websites work more efficiently and provide useful
                information to website owners.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                2. How We Use Cookies
              </h2>
              <h3 className="text-xl font-medium mb-2">
                2.1 Necessary Cookies
              </h3>
              <p className="text-gray-700 mb-4">
                These cookies are essential for the website to function
                properly. They enable basic functions like page navigation and
                access to secure areas of the website. The website cannot
                function properly without these cookies.
              </p>

              <h3 className="text-xl font-medium mb-2">
                2.2 Analytics Cookies
              </h3>
              <p className="text-gray-700 mb-4">
                These cookies help us understand how visitors interact with our
                website by collecting and reporting information anonymously.
                This helps us improve our website's structure and content.
              </p>

              <h3 className="text-xl font-medium mb-2">
                2.3 Marketing Cookies
              </h3>
              <p className="text-gray-700">
                These cookies are used to track visitors across websites. The
                intention is to display ads that are relevant and engaging for
                the individual user.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                3. Cookie Settings
              </h2>
              <p className="text-gray-700">
                You can adjust your cookie preferences at any time by clicking
                the "Cookie Settings" button in our cookie banner. You can also
                control cookies through your browser settings. Please note that
                disabling certain cookies may impact the functionality of our
                website.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                4. Specific Cookies We Use
              </h2>
              <div className="space-y-4">
                <div className="border-b pb-4">
                  <h3 className="font-medium mb-2">Necessary Cookies</h3>
                  <ul className="list-disc list-inside text-gray-700 ml-4">
                    <li>Cookie consent status</li>
                    <li>Session management</li>
                    <li>Security tokens</li>
                  </ul>
                </div>

                <div className="border-b pb-4">
                  <h3 className="font-medium mb-2">Analytics Cookies</h3>
                  <ul className="list-disc list-inside text-gray-700 ml-4">
                    <li>Google Analytics (_ga)</li>
                    <li>Page view statistics</li>
                    <li>User behavior tracking</li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-medium mb-2">Marketing Cookies</h3>
                  <ul className="list-disc list-inside text-gray-700 ml-4">
                    <li>Advertising preferences</li>
                    <li>Retargeting cookies</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                5. Third-Party Cookies
              </h2>
              <p className="text-gray-700">
                Some cookies are placed by third-party services that appear on
                our pages. We use these services to enhance our website's
                functionality. These third parties may use cookies for their own
                purposes. Please refer to their privacy policies for more
                information.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                6. More Information
              </h2>
              <p className="text-gray-700">
                For more information about how we use cookies and your personal
                data, please review our{" "}
                <Link
                  to="/privacy-policy"
                  className="text-teal-600 hover:text-teal-700 underline"
                >
                  Privacy Policy
                </Link>
                . If you have any questions, please contact us at
                privacy@spoecker.cloud.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
