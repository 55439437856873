// src/components/Hero.jsx
import React from "react";
import { Cloud, Shield, Zap, Target } from "lucide-react";

const Hero = () => {
  const benefits = [
    {
      icon: Shield,
      title: "Enterprise Security",
      description: "Secure solutions for private data and compliance",
    },
    {
      icon: Zap,
      title: "Rapid Implementation",
      description: "Fast deployment with AWS best practices",
    },
    {
      icon: Target,
      title: "Cost Optimization",
      description: "Efficient cloud solutions that reduce costs",
    },
  ];

  return (
    <section className="pt-32 pb-20 bg-gradient-to-r from-teal-600 to-teal-400">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center">
          <div className="flex justify-center mb-6">
            <Cloud size={64} className="text-white" />
          </div>
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
            Next-Gen Cloud & AI Solutions
          </h1>
          <p className="text-xl text-white mb-4">
            AWS Authorized Instructor | Community Builder | All AWS
            Certifications
          </p>
          <p className="text-lg text-white mb-12 max-w-2xl mx-auto">
            Accelerate your business with expert AWS solutions. From secure
            GenAI implementations to optimized cloud architectures, we deliver
            enterprise-grade solutions that drive innovation and reduce costs.
          </p>

          {/* Benefits Section */}
          <div className="grid md:grid-cols-3 gap-8 mb-12 max-w-5xl mx-auto">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="bg-white/10 backdrop-blur-sm rounded-lg p-6 text-white"
              >
                <div className="flex justify-center mb-4">
                  <benefit.icon size={32} className="text-white" />
                </div>
                <h3 className="font-semibold text-lg mb-2">{benefit.title}</h3>
                <p className="text-white/90 text-sm">{benefit.description}</p>
              </div>
            ))}
          </div>

          <div className="flex flex-col md:flex-row justify-center items-center gap-4">
            <a
              href="#contact"
              className="inline-block bg-white text-teal-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition duration-300"
            >
              Transform Your Business
            </a>
            <a
              href="#solutions"
              className="inline-block border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition duration-300"
            >
              Explore Solutions
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
