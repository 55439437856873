// src/components/Certifications.jsx
import React from "react";
import { GraduationCap } from "lucide-react";

const Certifications = () => {
  const certifications = {
    professional: [
      {
        title: "Solutions Architect",
        level: "Professional",
        image:
          "assets/images/aws/aws-certified-solutions-architect-professional.png",
      },
      {
        title: "DevOps Engineer",
        level: "Professional",
        image:
          "assets/images/aws/aws-certified-devops-engineer-professional.png",
      },
    ],
    associate: [
      {
        title: "Solutions Architect",
        level: "Associate",
        image:
          "assets/images/aws/aws-certified-solutions-architect-associate.png",
      },
      {
        title: "Developer",
        level: "Associate",
        image: "assets/images/aws/aws-certified-developer-associate.png",
      },
      {
        title: "SysOps Administrator",
        level: "Associate",
        image:
          "assets/images/aws/aws-certified-sysops-administrator-associate.png",
      },
      {
        title: "Data Engineer",
        level: "Associate",
        image: "assets/images/aws/aws-certified-data-engineer-associate.png",
      },
      {
        title: "Machine Learning Engineer",
        level: "Associate",
        image:
          "assets/images/aws/aws-certified-machine-learning-engineer-associate.png",
      },
    ],
    specialty: [
      {
        title: "Security",
        level: "Specialty",
        image: "assets/images/aws/aws-certified-security-specialty.png",
      },
      {
        title: "Advanced Networking",
        level: "Specialty",
        image:
          "assets/images/aws/aws-certified-advanced-networking-specialty.png",
      },
      {
        title: "Machine Learning",
        level: "Specialty",
        image: "assets/images/aws/aws-certified-machine-learning-specialty.png",
      },
      {
        title: "SAP on AWS",
        level: "Specialty",
        image: "assets/images/aws/aws-certified-sap-on-aws-specialty.png",
      },
    ],
    foundational: [
      {
        title: "Cloud Practitioner",
        level: "Foundational",
        image: "assets/images/aws/aws-certified-cloud-practitioner.png",
      },
      {
        title: "AI Practitioner",
        level: "Foundational",
        image: "assets/images/aws/aws-certified-ai-practitioner.png",
      },
    ],
  };

  return (
    <section id="expertise" className="py-20">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <GraduationCap size={48} className="text-teal-500 mx-auto mb-4" />
          <h2 className="text-3xl font-bold">AWS Certifications</h2>
          <p className="text-gray-600 mt-4">
            AWS Authorized Instructor & Complete AWS Certification Portfolio
          </p>
        </div>

        {/* Professional Certifications */}
        <div className="mb-12">
          <h3 className="text-xl font-semibold mb-6 text-center">
            Professional
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
            {certifications.professional.map((cert, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <div className="flex items-center space-x-4">
                  <img
                    src={cert.image}
                    alt={`${cert.title} ${cert.level}`}
                    className="w-16 h-16"
                  />
                  <div>
                    <h4 className="font-semibold text-gray-800">
                      {cert.title}
                    </h4>
                    <p className="text-gray-600">{cert.level}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Specialty Certifications */}
        <div className="mb-12">
          <h3 className="text-xl font-semibold mb-6 text-center">Specialty</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {certifications.specialty.map((cert, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <div className="flex items-center space-x-4">
                  <img
                    src={cert.image}
                    alt={`${cert.title} ${cert.level}`}
                    className="w-16 h-16"
                  />
                  <div>
                    <h4 className="font-semibold text-gray-800">
                      {cert.title}
                    </h4>
                    <p className="text-gray-600">{cert.level}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Associate Certifications */}
        <div className="mb-12">
          <h3 className="text-xl font-semibold mb-6 text-center">Associate</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {certifications.associate.map((cert, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <div className="flex items-center space-x-4">
                  <img
                    src={cert.image}
                    alt={`${cert.title} ${cert.level}`}
                    className="w-16 h-16"
                  />
                  <div>
                    <h4 className="font-semibold text-gray-800">
                      {cert.title}
                    </h4>
                    <p className="text-gray-600">{cert.level}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Foundational Certifications */}
        <div className="mb-12">
          <h3 className="text-xl font-semibold mb-6 text-center">
            Foundational
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
            {certifications.foundational.map((cert, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <div className="flex items-center space-x-4">
                  <img
                    src={cert.image}
                    alt={`${cert.title} ${cert.level}`}
                    className="w-16 h-16"
                  />
                  <div>
                    <h4 className="font-semibold text-gray-800">
                      {cert.title}
                    </h4>
                    <p className="text-gray-600">{cert.level}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certifications;
