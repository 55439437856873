// src/components/DevOps.jsx
import React from "react";
import { Code2 } from "lucide-react";

const DevOps = () => {
  return (
    <section id="devops" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <Code2 size={48} className="text-teal-500 mx-auto mb-4" />
          <h2 className="text-3xl font-bold mb-4">Modern DevOps & CI/CD</h2>
          <p className="text-xl text-gray-600">
            Automate, Deploy, Scale: Enterprise-Grade Pipeline Solutions
          </p>
        </div>

        {/* ECS Blue/Green Deployments */}
        <div className="bg-gradient-to-r from-gray-900 to-gray-800 rounded-xl p-8 mb-16">
          <div className="flex items-center justify-between mb-8">
            <h3 className="text-2xl font-bold text-white">
              ECS Blue/Green Deployments
            </h3>
            <div className="bg-teal-500/10 text-teal-400 px-4 py-2 rounded-full text-sm">
              Featured at AWS Summit Thailand 2024
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            {/* Deployment Visual */}
            <div className="bg-gray-800 rounded-lg p-6">
              <div className="space-y-6">
                {/* Blue Environment */}
                <div>
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
                    <h4 className="text-xl font-semibold text-white">
                      Blue Environment
                    </h4>
                  </div>
                  <div className="bg-gray-700 p-4 rounded">
                    <p className="text-white font-medium mb-2">ECS Service</p>
                    <div className="grid grid-cols-3 gap-2">
                      {[1, 2, 3].map((task) => (
                        <div key={task} className="bg-blue-500/20 p-2 rounded">
                          <p className="text-blue-300 text-sm">Task {task}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Green Environment */}
                <div>
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                    <h4 className="text-xl font-semibold text-white">
                      Green Environment
                    </h4>
                  </div>
                  <div className="bg-gray-700 p-4 rounded">
                    <p className="text-white font-medium mb-2">
                      New ECS Service
                    </p>
                    <div className="grid grid-cols-3 gap-2">
                      {[1, 2, 3].map((task) => (
                        <div key={task} className="bg-green-500/20 p-2 rounded">
                          <p className="text-green-300 text-sm">Task {task}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Features */}
            <div className="text-white">
              <h4 className="text-xl font-semibold mb-6">
                ECS CodeDeploy Features
              </h4>
              <ul className="space-y-4">
                {[
                  {
                    title: "Production-Ready ECS Deployments",
                    description:
                      "Automated container orchestration with zero downtime",
                  },
                  {
                    title: "Traffic Shifting",
                    description:
                      "Configurable traffic routing between environments",
                  },
                  {
                    title: "Container Health Monitoring",
                    description:
                      "Automated health checks and rollback triggers",
                  },
                  {
                    title: "Application Load Balancer Integration",
                    description:
                      "Seamless traffic management and SSL termination",
                  },
                ].map((feature, index) => (
                  <li key={index} className="flex items-start space-x-3">
                    <div className="flex-shrink-0 w-6 h-6 bg-teal-500 rounded-full flex items-center justify-center mt-1">
                      <div className="w-2 h-2 bg-white rounded-full"></div>
                    </div>
                    <div>
                      <p className="font-medium">{feature.title}</p>
                      <p className="text-gray-400">{feature.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Implementation Steps */}
          <div className="border-t border-gray-700 pt-8">
            <h4 className="text-xl font-semibold text-white mb-6">
              Implementation Process
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              {[
                {
                  step: "1. Setup",
                  description: "ECS Task Definition & Service Configuration",
                },
                {
                  step: "2. CodeDeploy",
                  description: "Deployment Group & AppSpec Configuration",
                },
                {
                  step: "3. Testing",
                  description: "Health Check & Validation Rules",
                },
                {
                  step: "4. Deployment",
                  description: "Automated Traffic Shifting & Monitoring",
                },
              ].map((step, index) => (
                <div key={index} className="bg-gray-800 p-4 rounded-lg">
                  <p className="text-teal-400 font-medium mb-2">{step.step}</p>
                  <p className="text-gray-300 text-sm">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DevOps;
