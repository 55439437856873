// src/utils/accessibility.js
export const handleKeyboardNavigation = (e, onClick) => {
  if (e.key === "Enter" || e.key === " ") {
    e.preventDefault();
    onClick();
  }
};

export const skipToMainContent = () => {
  const main = document.querySelector("main");
  if (main) {
    main.focus();
    main.scrollIntoView();
  }
};
