// src/pages/Home.jsx
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Hero from "../components/Hero";
import GenAI from "../components/GenAI";
import Solutions from "../components/Solutions";
import DevOps from "../components/DevOps";
import Certifications from "../components/Certifications";
import Speaking from "../components/Speaking";
import Contact from "../components/Contact";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  return (
    <div className="space-y-12">
      {" "}
      {/* Reduced from space-y-20 */}
      <Hero />
      <GenAI />
      <Solutions />
      <DevOps />
      <Certifications />
      <Speaking />
      <Contact />
    </div>
  );
};

export default Home;
