// src/pages/DataProcessingAgreement.jsx
import React from "react";

const DataProcessingAgreement = () => {
  return (
    <div className="py-20 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8">
          Data Processing Agreement (DPA)
        </h1>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <p className="text-gray-600 mb-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
              <p className="text-gray-700">
                This Data Processing Agreement ("DPA") forms part of our Terms
                of Service and reflects our commitment to protecting personal
                data in accordance with GDPR, CCPA, and other applicable data
                protection laws.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">2. Definitions</h2>
              <div className="space-y-4">
                <p className="text-gray-700">
                  "Personal Data," "Processing," "Controller," "Processor," and
                  other capitalized terms have the meanings given in the GDPR.
                </p>
                <p className="text-gray-700">
                  "Services" means the cloud consulting and implementation
                  services provided by Spoecker Cloud Consulting LLC.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                3. Data Processing
              </h2>
              <div className="space-y-4">
                <h3 className="text-xl font-medium mb-2">
                  3.1 Scope and Purpose
                </h3>
                <p className="text-gray-700">
                  We process personal data solely for the purpose of providing
                  our Services and as instructed by you in writing.
                </p>

                <h3 className="text-xl font-medium mb-2">3.2 Duration</h3>
                <p className="text-gray-700">
                  This DPA remains in effect for the duration of our processing
                  of your personal data.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                4. Security Measures
              </h2>
              <p className="text-gray-700 mb-4">
                We implement appropriate technical and organizational measures
                to ensure a level of security appropriate to the risk,
                including:
              </p>
              <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
                <li>Encryption of personal data</li>
                <li>Secure cloud infrastructure</li>
                <li>Access controls and authentication</li>
                <li>Regular security assessments</li>
                <li>Employee training and confidentiality agreements</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">5. Subprocessors</h2>
              <p className="text-gray-700">
                We may engage subprocessors to assist in providing our Services.
                We will ensure that subprocessors are bound by written
                agreements that require them to provide at least the level of
                data protection required by this DPA.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                6. Data Subject Rights
              </h2>
              <p className="text-gray-700">
                We will assist you in responding to requests from Data Subjects
                to exercise their rights under applicable data protection laws.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">7. Data Transfers</h2>
              <p className="text-gray-700">
                Any transfer of personal data to a third country or
                international organization will be done in accordance with
                applicable data protection laws.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                8. Data Breach Notification
              </h2>
              <p className="text-gray-700">
                We will notify you without undue delay after becoming aware of a
                personal data breach and will assist you in meeting your data
                breach reporting obligations.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">9. Audit Rights</h2>
              <p className="text-gray-700">
                We will make available all information necessary to demonstrate
                compliance with this DPA and allow for audits by you or an
                auditor mandated by you.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4">
                10. Contact Information
              </h2>
              <p className="text-gray-700">
                For any questions about this DPA or our data processing
                activities, please contact:
              </p>
              <div className="mt-4 text-gray-700">
                <p>Data Protection Officer</p>
                <p>Spoecker Cloud Consulting LLC</p>
                <p>Email: privacy@spoecker.cloud</p>
                <p>[Your Business Address]</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataProcessingAgreement;
